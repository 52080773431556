<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="creditConfirmData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingCredits"
      :loading-text="loadingText"
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por descripción / monto / etc."
            outlined
            dense
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="((rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado' || rol=='Cobrador') && stateId===3)"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="$refs.dialogCrediotCollection.openDialog(item.creditHistoryId)"
            >
              {{ icons.mdiFolderEyeOutline }}
            </v-icon>
          </template>
          <span>Registrar Pagos</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="((rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado') && stateId===1)"
              size="18"
              class="me-3"
              v-bind="attrs"
              color="success"
              v-on="on"
              @click="aproveCredit(item)"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </template>
          <span>Aprobar Credito</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="((rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado') && stateId === 2)"
              size="18"
              class="me-3"
              v-bind="attrs"
              color="success"
              v-on="on"
              @click="opemDialogGivCredit(item)"
            >
              {{ icons.mdiHandCoin }}
            </v-icon>
          </template>
          <span>Entregar Dinero</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="((rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado') && stateId != 3 && stateId != 5 && stateId != 4)"
              size="18"
              class="me-3"
              v-bind="attrs"
              color="error"
              v-on="on"
              @click="rejectCredit(item)"
            >
              {{ icons.mdiCancel }}
            </v-icon>
          </template>
          <span>Rechazar Crédito</span>
        </v-tooltip>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip
          :color="getColor(item.state)"
          dark
          small
          style="width:80%; text-align: justify;"
        >
          {{ item.state }}
        </v-chip>
      </template>
    </v-data-table>
    <dialogCreditCollection ref="dialogCrediotCollection"></dialogCreditCollection>

    <dialogo
      ref="dialogEntregarCredito"
      :mensaje="mensajeentregar"
      @confirmar="giveAmountCredit()"
    ></dialogo>
    <dialogo
      ref="dialogRechazarCredito"
      :mensaje="mensajerechazar"
      @confirmar="rejectCreditConfirm()"
    ></dialogo>
    <dialogo
      ref="dialogAprobarCredito"
      :mensaje="mensajeaprobar"
      @confirmar="aproveCreditConfirm()"
    ></dialogo>
  </v-container>
</template>

<script>
import {
  mdiPenPlus, mdiFolderEyeOutline, mdiDeleteEmpty, mdiCheckCircle, mdiCancel, mdiHandCoin,
} from '@mdi/js'

import axios from 'axios'
import dialogo from '../../../components/base/DialogGlobal.vue'

import dialogCreditCollection from '../creditCollections.vue'

export default {
  components: { dialogCreditCollection, dialogo },
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
    stateId: {
      // eslint-disable-next-line no-undef
      type: Number,
      require: true,
      default: 1,
    },
    // eslint-disable-next-line vue/require-prop-types
    loadingText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    mensajeaprobar: '¿Está seguro que desea aprobar el crédito ?',
    mensajerechazar: '¿Está seguro que desea RECHAZAR el crédito?',
    mensajeentregar: '¿Al entregar este crédito al cliente se descontará el monto de la caja desea continuar?',
    icons: {
      mdiCancel,
      mdiPenPlus,
      mdiFolderEyeOutline,
      mdiDeleteEmpty,
      mdiCheckCircle,
      mdiHandCoin,
    },

    headers: [
      {
        text: 'Dni',
        align: 'start',
        sortable: true,
        value: 'clientDNI',
      },

      { text: 'Cliente', value: 'client' },
      { text: 'Cuotas', value: 'quotas' },
      { text: 'Monto', value: 'amount' },
      { text: 'Fecha Solicitud', value: 'requestedDate' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    creditConfirmData: [],
    buscar: '',
    loadingCredits: false,
    mensajeSnack: '',
    creditToaprove: null,
    dialogAprove: false,
    creditToReject: null,
    dialogReject: false,
    dialogGiv: false,
    creditToGiv: null,
  }),
  mounted() {
    this.getCreditConfirm()
    if (!('geolocation' in navigator)) {
      this.lat = '0'
      this.lon = '0'

      return alert(
        'Tu navegador no soporta el acceso a la ubicación. Intenta con otro',
      )
    }
  },
  methods: {
    opemDialogGivCredit(item) {
      this.mensajeentregar = `¿Al entregar este crédito a ${item.client} se descontará $${item.amount} de la caja desea continuar?`,
      this.creditToGiv = item
      this.$refs.dialogEntregarCredito.OpenDialog()
    },
    aproveCredit(item) {
    
      this.mensajeaprobar = `¿Está seguro que desea aprobar el crédito ${item.description} para ${item.client} ?`
      this.creditToaprove = item
      this.$refs.dialogAprobarCredito.OpenDialog()
    },

    rejectCredit(item) {
      this.mensajerechazar = `¿Está seguro que desea RECHAZAR el crédito para ${item.client}?`
      this.creditToReject = item
      this.$refs.dialogRechazarCredito.OpenDialog()
    },

    async rejectCreditConfirm() {
      this.url = `${this.$store.getters.urlBase}/api/Credit/aproveDesaproveCreditHistory`
      const params = {
        // eslint-disable-next-line radix
        creditHistoryId: parseInt(this.creditToReject.creditHistoryId),
        aprovedDesaproved: false,
        // eslint-disable-next-line radix
        personIDLogged: parseInt(this.$store.getters.usuario.user_personid),

      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params,
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            this.$store.dispatch('showSnack', {
              text: 'Credito Rechazado',
              color: 'success',
              timeout: 2500,
            })
            this.getCreditConfirm()
          },
        )
        .catch(e => {
          if (e.response) {
            this.mensajeSnack = e.response.data
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
        })
    },

    async giveAmountCredit() {
      this.url = `${this.$store.getters.urlBase}/api/Credit/GiveAmountOfCreditRequestedToClient`
      const params = {
        // eslint-disable-next-line radix
        creditHistoryId: parseInt(this.creditToGiv.creditHistoryId),
        // eslint-disable-next-line radix
        personIDLogged: parseInt(this.$store.getters.usuario.user_personid),

      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params,
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            this.$store.dispatch('showSnack', {
              text: 'Operación Exitosa',
              color: 'success',
              timeout: 2500,
            })
            this.getCreditConfirm()
          },
        )
        .catch(e => {
          if (e.response) {
            this.mensajeSnack = e.response.data
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
        })
    },

    async aproveCreditConfirm() {
      this.url = `${this.$store.getters.urlBase}/api/Credit/aproveDesaproveCreditHistory`

      const params = {
        // eslint-disable-next-line radix
        creditHistoryId: parseInt(this.creditToaprove.creditHistoryId),
        aprovedDesaproved: true,
        // eslint-disable-next-line radix
        personIDLogged: parseInt(this.$store.getters.usuario.user_personid),

      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params,
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            this.$store.dispatch('showSnack', {
              text: 'Credito Aprobado',
              color: 'success',
              timeout: 2500,
            })
            this.getCreditConfirm()
          },
        )
        .catch(e => {
          if (e.response) {
            this.mensajeSnack = e.response.data
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
        })
    },
    CreditCollections(creditHistoryId) {
      this.$router.push({ name: 'creditCollections', params: { id: creditHistoryId } })
    },
    async getCreditConfirm() {
      let idPerson = null
      if (this.$store.getters.usuario.user_rol === 'Cobrador') {
        idPerson = parseInt(this.$store.getters.usuario.user_personid)
      }
      this.url = `${this.$store.getters.urlBase}/api/Credit/CreditsByCreditState`
      const params = {}
      params.loggedPersonId = idPerson
      params.creditStateId = this.stateId
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        params,

        url: this.url,
      }
      this.loadingCredits = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.creditConfirmData = respuesta.data
        this.loadingCredits = false
      } else {
        this.loadingCredits = false
      }
    },

  },
}
</script>
