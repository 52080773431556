var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.creditConfirmData,"item-key":"id","sort-by":"name","search":_vm.buscar,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"loading":_vm.loadingCredits,"loading-text":_vm.loadingText,"no-data-text":"No hay registros para mostrar.","no-results-text":"No se encontraron registros en la búsqueda"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por descripción / monto / etc.","outlined":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol=='Encargado' || _vm.rol=='Cobrador') && _vm.stateId===3))?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18"},on:{"click":function($event){return _vm.$refs.dialogCrediotCollection.openDialog(item.creditHistoryId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFolderEyeOutline)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Registrar Pagos")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol=='Encargado') && _vm.stateId===1))?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18","color":"success"},on:{"click":function($event){return _vm.aproveCredit(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Aprobar Credito")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol=='Encargado') && _vm.stateId === 2))?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18","color":"success"},on:{"click":function($event){return _vm.opemDialogGivCredit(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiHandCoin)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Entregar Dinero")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol=='Encargado') && _vm.stateId != 3 && _vm.stateId != 5 && _vm.stateId != 4))?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18","color":"error"},on:{"click":function($event){return _vm.rejectCredit(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rechazar Crédito")])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"80%","text-align":"justify"},attrs:{"color":_vm.getColor(item.state),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}}])}),_c('dialogCreditCollection',{ref:"dialogCrediotCollection"}),_c('dialogo',{ref:"dialogEntregarCredito",attrs:{"mensaje":_vm.mensajeentregar},on:{"confirmar":function($event){return _vm.giveAmountCredit()}}}),_c('dialogo',{ref:"dialogRechazarCredito",attrs:{"mensaje":_vm.mensajerechazar},on:{"confirmar":function($event){return _vm.rejectCreditConfirm()}}}),_c('dialogo',{ref:"dialogAprobarCredito",attrs:{"mensaje":_vm.mensajeaprobar},on:{"confirmar":function($event){return _vm.aproveCreditConfirm()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }