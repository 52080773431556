<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          {{ mensaje }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="Confirmar"
          >
            Confirmar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {

  props: {
    mensaje: {
      type: String,
      required: true,
      default: 'mensaje',
    },
  },
  data: () => ({
    dialog: false,
  }),

  methods: {
    CloseDialog() {
      this.dialog = false
    },
    Confirmar() {
      this.$emit("confirmar");
      this.dialog = false
    },
    OpenDialog() {
      this.dialog = true
    },

  },
}
</script>
